import React, { useContext, useEffect, useState } from 'react';
import App from './App';
import { BrowserRouter as Router, Routes, Route, redirect, Redirect, useLocation, Navigate, useNavigate } from "react-router-dom";
import MainData from './components/MainData/MainData';
import { GlobalVar, Context, AlertContext } from './index.js'
import logo from './images/cicada_gold.png'


import Button from '@mui/material/Button';
import { SnackbarProvider, SnackbarContent, CustomContentProps } from 'notistack';
import { ConfigProvider, notification } from 'antd';
import { _ } from '@ag-grid-community/core';
import BasePage from './pages/Base/base.jsx';

import locale from 'antd/locale/ru_RU';
import ContactPage from './pages/ContactPage/ContactPage';
import app_routers from './routers.js';

const selector_subdomain = () => {
  let test = true
  let dots = window.location.hostname.split('.').length - 1;
  // если 2 точки, то base (localhost, cicada)
  let subdomain = 'base';
  let count_dots = test ? 0 : 1;
  if (dots == count_dots) {
    subdomain = 'base';
  } else {
    subdomain = window.location.hostname.split('.')[0];
  }
  return subdomain; 
}

const selector_router = () => {
  let subdomain = selector_subdomain();
  return app_routers[subdomain];
}

function ProtectedRoute({ children, auth, auth_data, ...props }) {
  const navigate = useNavigate();
  if (auth === true) {
    if (auth_data.store.isAuth === true) {
      return children
    } else {
      return navigate("/login", { replace: true })
    }
  } else {
    return (children)
  }
}


function ReportCompleteSnackbar(props) {
  console.log('ReportCompleteSnackbar', props);
  return (
    <SnackbarContent style={{ backgroundColor: 'green' }} message="Report Complete">
      <Button color="inherit" size="small" onClick={() => props.closeSnackbar(props.key)} />
    </SnackbarContent>
  );
}


export default function RouterP(props) {
  let [bottomAlertData, setBottomAlert] = useState({});
  let bottom_alert = {
    bottomAlertData,
    setBottomAlert,
  }
  let [userDetails, setUserDetails] = useState({
    isAuth: false,
  })
  let UD = {
    userDetails,
    setUserDetails,
  }

  let _GlobalVar = React.useContext(GlobalVar);
  // console.log('_GlobalVar', _GlobalVar);
  _GlobalVar.sa = setBottomAlert || null;

  let _Context = React.useContext(Context);

  let _AlertContext = React.useContext(AlertContext);
  // console.log('_AlertContext', _AlertContext);
  const [api, contextHolder] = notification.useNotification();
  _AlertContext.setData([api, contextHolder] || [null, null]);

  let router = selector_router();

  useEffect(() => { 
    // переадресация на https://cicadaholding.com
    window.location.href = 'https://cicadaholding.com';
  }, []);
  
  return (
    // Переадресовать на https://cicadaholding.com
    <img src={logo} className={''}
      style={{
        width: '100%',
        height: '100%',
        objectFit: 'contain',
        objectPosition: 'center',
        position: 'absolute',
        top: '0',
        left: '0',
      }}
      alt="Cicada logo" />
    // 
    // <>
    //   <ConfigProvider locale={locale}>
    //     {contextHolder}
    //     <SnackbarProvider
    //       maxSnack={2}
    //       hideIconVariant
    //       autoHideDuration={3000}
    //       anchorOrigin={{
    //         vertical: 'bottom',
    //         horizontal: 'left',
    //       }}
    //       persist={true}

    //       components={{
    //         reportComplete: ReportCompleteSnackbar,
    //       }}

    //     >
    //       <Router>
    //         {/* <MainData UD={UD} bottom_alert={bottom_alert} /> */}
    //         {/* <App UD={UD} /> */}
    //         <Routes>
    //           {router.map((route, i) => (

    //             <Route
    //               key={i}
    //               path={route.path}
    //               exact={true}
    //               element={
    //                 <>
    //                   <route.element UD={UD} bottom_alert={bottom_alert} />
    //                 </>
    //               }
    //             />

    //           ))}
    //         </Routes>
    //       </Router>
    //     </SnackbarProvider>
    //   </ConfigProvider>
    // </>
  );
}