
import s from './error.module.scss'
function ErrorPage(props) {
  return (
    <div className={s['error']}>
      Error 404
    </div>
  )
}

export default ErrorPage