
import { Progress } from 'antd';
import s from './projectsPage.module.scss'
import { useEffect, useState } from 'react';


function ProgressBar({ percent }) {
  const [percentValue, setPercentValue] = useState(0);
  const twoColors = {
    '0%': '#373737b8',
    '100%': '#008bff',
  };
  useEffect(() => {
    setPercentValue(percent * 100);
    console.log(percentValue === 1, percentValue)
  }, [])
  return (
    <div className={s['progress']}>
      {/* {{percent}} */}
      <Progress
        percent={percentValue}
        status={percentValue === 100}
        strokeColor={percentValue === 100 ? '#008bff' : twoColors}
        showInfo={false} />
    </div>
  )
}

function ProjectBlock(project) {
  const [activeProject, setActiveProject] = useState(null);

  useEffect(() => {
    setActiveProject(project.sold_lots / project.total_lots === 1);
  }, [])

  const onAboutClick = () => {
    // открыть в новой вкладке ссылку
    let link = project.about;
    window.open(link, '_blank');
  }

  return (

    <div className={s['project']} type={project.type}>
      <div className={s['box']}>
        <div className={s['header']}>
          <div className={s['logo']}>
            <img src="https://app.amcapital.pro/media/companies/03-03-2023/a46f21de0da6494a.png" alt="" />
          </div>
          <div className={`${s['type']}`} >
            {project.type}
          </div>
          <div className={`${s['ra']} ${activeProject ? s['disabled'] : ''}`} ></div>
        </div>
        <div className={s['desc']}>
          {project.desc}
        </div>
      </div>
      <div className={s['bottom_panel']}>
        <div className={s['numbers']}>
          <div className={s['price']}>{project.lot_price}</div>
          <div className={s['progress_top']}>
            <div className={s['desc']}>{project.total_lots - project.sold_lots === 0 ? 'Все лоты куплены' : `Осталось ${project.total_lots - project.sold_lots} лотов`}</div>
            <div className={s['info']}>{project.sold_lots}/{project.total_lots}</div>
          </div>
          <ProgressBar percent={project.sold_lots / project.total_lots} />
          <div className={s['info']}>
            <div className={s['obj']}>
              <div className={s['title']}>Оценка проекта</div>
              <div className={s['value']}>{project.project_estimate}</div>
            </div>
            <div className={s['obj']}>
              <div className={s['title']}>Потенцеальная доходность</div>
              <div className={s['value']}>{project.potential_profit}</div>
            </div>
          </div>
        </div>
        <div className={s['buttons']}>
          <div className={`${s['btn']} ${s['buy']} ${activeProject ? s['disabled'] : ''}`}>Купить</div>
          <div className={`${s['btn']} ${s['info']} ${activeProject ? s['disabled'] : ''} ${project?.about ? s['active'] : s['disabled']}`} onClick={() => { onAboutClick() }}>Подробнее</div>
        </div>
      </div>
    </div >
  )
}


function VcProjectsPage(props) {
  const projects = [
    {
      title: 'CICADA innovate holding',
      slogan: 'Технологии меняют мир, мы меняем технологии',
      desc: "Мировой лидер в области игр на блокчейне и NFT",

      // всего лотов к продаже
      total_lots: '1000',
      lot_price: '$100',
      // продано лотов
      sold_lots: '300',

      // Оценка проекта
      project_estimate: '$ 1.7 млрд',
      // потенциальная доходность
      potential_profit: '170+%',

      type: "venture"
    },
    {
      title: 'CICADA innovate holding',
      slogan: 'Технологии меняют мир, мы меняем технологии',
      desc: "Мировой лидер в области игр на блокчейне и NFT",

      // всего лотов к продаже
      total_lots: '1000',
      lot_price: '$100',
      // продано лотов
      sold_lots: '900',

      // Оценка проекта
      project_estimate: '$ 1.7 млрд',
      // потенциальная доходность
      potential_profit: '170+%',

      type: "venture"
    },
    {
      title: 'CICADA innovate holding',
      slogan: 'Технологии меняют мир, мы меняем технологии',
      desc: "Мировой лидер в области игр на блокчейне и NFT",

      // всего лотов к продаже
      total_lots: '1000',
      lot_price: '$100',
      // продано лотов
      sold_lots: '100',

      // Оценка проекта
      project_estimate: '$ 1.7 млрд',
      // потенциальная доходность
      potential_profit: '170+%',

      type: "venture"
    },
    {
      title: 'CICADA innovate holding',
      slogan: 'Технологии меняют мир, мы меняем технологии',
      desc: "Быстрорастущая компания, основанная тремя выпускниками Гарварда, предоставляет пакет SaaS решений для продавцов торговых площадок, таких как Amazon, Walmart, Taobao, JD, eBay.",

      // всего лотов к продаже
      total_lots: '1000',
      lot_price: '$100',
      // продано лотов
      sold_lots: '1000',

      // Оценка проекта
      project_estimate: '$ 1.7 млрд',
      // потенциальная доходность
      potential_profit: '170+%',

      type: "startup",

      'about': 'https://www.youtube.com/---'
    },
  ]



  return (
    <div className={s['projects']}>
      Vc Projects Page
      <div className={s['projects__catalog']}>
        {
          projects.map(project => {
            return ProjectBlock(project)
          })
        }
      </div>
    </div>
  )
}

export default VcProjectsPage