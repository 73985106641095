import s from './base.module.scss'
import logo from '../../images/cicada_gold.png'
// import Logo from '../images/profile-4.jpg'

function BasePage(props) {

  const toURL = (type) => {
    // заменить 
    if (type === 'contact') {
      window.location.href = 'https://teletype.link/amal_agishev';
    }
    else if (type === 'presentation_ru') {
      window.location.href = 'https://files.cicada.llc/presentation.pdf';
    }
    else if (type === 'presentation_en') {
      window.location.href = 'https://files.cicada.llc/en_presentation.pdf';
    }
  }



  return (
    <>
      <div className={s['bg']}>
        <div className={s['base']}>
          <section className={s["section_logo"]}>
            <h1 className={s["logo"]}>
              <img src={logo} className={s['img']} alt="Cicada logo" />
            </h1>
          </section>
          <section className={s["section_info"]}>
            <h1 className={s['alert']}>The site is currently in the final stages of development</h1>
            <h1 className={s['subtitle']}>See you soon</h1>
            <div className={`${s['btn']} ${s['multy']}`}>
              <span className={s['title']}>Read more</span>
              <div className={s['buttons']}>
                <div className={s['btns']} onClick={() => { toURL('presentation_en') }}>EN</div>
                <div className={s['btns']} onClick={() => { toURL('presentation_ru') }}>RU</div>

              </div>
            </div>

            {/* <button className={s['btn']} onClick={() => { toURL('presentation_ru') }}>Read more</button> */}
            <button className={`${s['btn']} ${s['c']}`} onClick={() => { toURL('contact') }}>Contact us</button>
          </section>

          <h3 className={s['footer']}>"Technology changes the world, we change technology."</h3>

        </div>
      </div>
    </>
  )

}

export default BasePage