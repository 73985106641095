import axios from "axios";
import { APIL } from "../env";



const api = axios.create({
  withCredentials: true,
  baseURL: (APIL) + '/api/v2',
})

api.interceptors.request.use(config => {
  config.headers.Authorization = `Bearer ${localStorage.getItem('access_token')}`
  config.headers['Access-Control-Allow-Origin'] = '*'
  return config
})
api.interceptors.response.use(config => {
  return config
},
  async (error) => {
    try {
      console.log('error', error);
      const originalResponse = error.config
      if (error.response.status === 401 && error.config && !error.config._isRetry) {
        console.log('ass');
        originalResponse._isRetry = true
        try {
          console.log('Ошибка в запросе')
          localStorage.removeItem('access_token')
          // const res = await api.post('/refresh_token')
          // console.log('res', res);
          // localStorage.setItem('access_token', res.data.access_token)

        } catch (e) {
          console.log('Не авторизован')
        }
        if (error.response.data?.error) {
          console.log(error.response.data);
          return error.response.data
        }
      }
      // console.log('ad', error);
      return error
    } catch (e) {
      return error
      throw Error
    }
  }
)

export default api