// import React from 'react'
import { makeAutoObservable } from 'mobx'
import { Space, Button, notification } from 'antd';
import './AlertContext.scss'
import { AddAlertOutlined } from '@mui/icons-material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Box } from '@mui/system';

export class AlertContextClass {
    api = 'ap'
    contextHolder = null

    constructor() {
        makeAutoObservable(this)
    }
    setApi(api) { this.api = api }
    setContext(contextHolder) { this.contextHolder = contextHolder }
    setData(data) {
        this.api = data[0]
        this.contextHolder = data[1]
    }

    getContext() { return this.contextHolder }
    getApi() { return this.api }

    getAll() { return this }
    getTest() { return 'test' }


    setCostomAlert = ({ type, message, description, error, code, placement }) => {

        function onCopy(val) {
            // скоировать в буфер обмена
            navigator.clipboard.writeText(val);
        }

        console.log('aga');
        const key = `open${message}`;
        const Btn = (
            <Space>
                <Button type="link" size="small" onClick={() => this.api.destroy()}>
                    Закрыть все
                </Button>
                <Button type="primary" size="small" onClick={() => this.api.destroy(key)}>
                    Закрыть
                </Button>
            </Space>
        );
        const bottom = (
            <Box class='ant-notification-notice-bottom'>
                <div class="ant-notification-notice-bottom-code" onClick={onCopy(`${error}_${code}`)}>
                    {code}
                    <ContentCopyIcon fontSize='small' />
                </div>
                {Btn}
            </Box>
        )

        placement = placement || 'bottomRight'
        this.api.open({
            message: message,
            description: description,
            btn: bottom,
            key,
            placement,
            className: 'AlertContext black-theme alert-custom',
            // duration: 0,
            icon: <AddAlertOutlined fontSize='small' />,
            
            // onClose: close,
        });
    }
}
// export const alertContext = new AlertContextClass();
// export const AlertContext = React.createContext({});

export default AlertContextClass;


// // класс машины
// class Car {
//     constructor(name) {
//         this.name = name;
//     }
//     getName() {
//         return this.name;
//     }
// }
// // класс машины с двигателем
// class CarWithEngine extends Car {
//     constructor(name, engine) {
//         super(name);
//         this.engine = engine;
//     }
//     getEngine() {
//         return this.engine;
//     }
// }