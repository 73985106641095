
import { makeAutoObservable } from 'mobx'
import AuthService from "../services/AuthService";

export default class Store {
  user = {}
  isAuth = false
  // isLoading = false

  constructor() {
    makeAutoObservable(this)
  }

  setAuth(bool) {
    this.isAuth = bool
  }

  setUser(user) {
    this.user = user
  }

  // setLoading(bool){
  //   this.isLoading = bool
  // }

  async auth(login, password, companyLogin) {
    try {
      console.log('companyLogin', companyLogin)
      const res = await AuthService.auth(login, password, companyLogin)
      if (res?.code) {
        return res?.response?.data
      } else {
        localStorage.setItem('access_token', res.data.access_token)
        this.setAuth(true)
        this.setUser(res.data.user)
        return res
      }
    } catch (e) {
      return false
    }
  }
  async logout() {
    try {
      this.setAuth(false)
      this.setUser({})
      localStorage.removeItem('access_token')
      await AuthService.logout()
    } catch (e) {
      console.log(e.response?.data?.message)
    }
  }
  async checkAuth(setLoad) {
    try {
      console.log('ps');
      const res = await AuthService.check()
      console.log('check', res.data)
      // localStorage.setItem('access_token', res.data.access_token)
      // console.log(res.data)
      this.setAuth(res.data.is_auth)
      // this.setUser(res.data.user)
    } catch (e) {
      console.log(e.response?.data?.message)
    } finally {
      setTimeout(() => {
        setLoad(true)
      }, 1000);

    }
  }
}