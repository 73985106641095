
import React, { useContext, useEffect, useState } from "react";
import { Zoom } from 'react-preloaders2';
import { Context } from "./index";
// import { QueryClient, QueryClientProvider, useQuery } from 'react-query'
// const queryClient = new QueryClient()
import { observer } from "mobx-react-lite";
// import Admin from "./pages/Admin";
import './scss/style.scss'
// import './styles/new/style.css'
// import Login from "./pages/Login";
import { redirect, useNavigate, Navigate } from "react-router-dom";

import { CircularProgress } from '@mui/material';
import VersionBlock from "./components/VersionBlock/VersionBlock";
// import {useNavigate} from "react-router-dom";

function App(props) {

  const [load, setLoad] = useState(false)
  const [windowLoad, setWindowLoad] = useState(false)
  const { store } = useContext(Context)
  const navigate = useNavigate();

  // Проверка аунтификации
  useEffect(() => {
    if (localStorage.getItem('access_token')) {
      console.log('проверяем')
      store.checkAuth(setLoad).then(() => {
        if (!store.isAuth && window.location.pathname !== '/login') {
          console.log('Возвращаем Login')
          props?.UD?.setUserDetails({
            ...props?.UD?.userDetails,
            isAuth: false,
          })
          window.location.pathname = '/login'
        } else {
          // if (window.location.pathname !== '/') { 
          //   window.location.pathname = '/'
          // }
          props?.UD?.setUserDetails({
            ...props?.UD?.userDetails,
            isAuth: true,
          })

        }
      })
    } else {
      if (!store.isAuth && window.location.pathname !== '/login') {
        console.log('Возвращаем Login')
        window.location.pathname = '/login'
      }
    }
    console.log('Авторизован ли', store.isAuth)
    setTimeout(() => {
      setLoad(true)
    }, 1000);
    // setWindowLoad(true)

  }, [])
  // Ждём загрузки всей страницы
  useEffect(() => {
    window.onload = function () {
      console.log('Страница загрузилась')
      // time sleep 1s
      setTimeout(() => {
        setWindowLoad(true)
      }, 1000);
    }
  }, [])
  const Allload = windowLoad || load
  console.log('Allload', Allload, windowLoad, load);

  return (
    <React.Fragment>
      {!Allload &&
        <div className="preloader">
          <CircularProgress />
        </div>
      }
      <VersionBlock />
    </React.Fragment>
  )
}

export default observer(App);
