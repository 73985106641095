import BasePage from "./pages/Base/base";
import ContactPage from "./pages/ContactPage/ContactPage";
import ErrorPage from "./pages/ErrorPage/ErrorPage";
import VcBasePage from "./pages/vc/basePage/basePage";
import VcProjectsPage from "./pages/vc/projectsPage/projectsPage";

const app_routers = {
  'base': [
    {
      path: "/",
      element: BasePage
    },
    {
      path: "*",
      element: ErrorPage
    }
  ],
  
  'contact': [
    {
      path: "/:id",
      element: ContactPage,
    },
    {
      path: "*",
      element: ErrorPage
    }
  ],

  'vc': [
    {
      'path': '/',
      'element': BasePage
    },
    {
      'path': '/projects-212311221',
      'element': VcProjectsPage
    }
  ]
}


export default app_routers;