import NewHeader from "../../components/new/NewHeader";
import BottomAlert from "../../components/alerts/bottom_alert";
import { useEffect } from "react";

function MainArea(props) {
    // console.log('this.Context', this.Context);
    useEffect(() => {
        console.log('UD', props?.UD?.userDetails);
    }, [props?.UD?.userDetails])
    return (
        <>
            {/* И url не раверн /login */}
            {props?.UD?.userDetails?.isAuth === true && window.location.pathname !== '/login'
                ? <NewHeader {...props} />
                : null
            }
            {props?.bottom_alert &&
                <BottomAlert alert={props?.bottom_alert?.bottomAlertData} setAlert={props?.bottom_alert?.setBottomAlert} />
            }
        </>
        // вернуть setAlert

    )

}

export default MainArea