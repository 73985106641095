import api from "../http";


export default class AuthService {
  static async auth(login, password, companyLogin) {
    console.log('companyLogin', companyLogin)
    return api.post('/auth', {
      'login': login,
      'password': password,
      'company_login': companyLogin
    })
  }
  static async logout() {
    return api.post('/logout')
  }
  static async refresh() {
    return api.post('/refresh_token')
  }
  static async check() {
    return api.get('/check_auth')
  }
}