import React, { createContext } from 'react';
import ReactDOM from 'react-dom/client';
import Store from "./store";

import RouterP from './Router';
import AlertContextClass from './context/AlertContext/AlertContext';
import { APIL, APILink } from './env';
import { Watermark } from 'antd';

const store = new Store()
console.log('store', store);
export const Context = createContext({
  store
})

// use ref
// console.log(api, contextHolder);
const alertContext = new AlertContextClass()

console.log('alertContext', alertContext);

export const AlertContext = React.createContext({
  alertContext
});


let data_GlobalVar = {
  isAuth: false,
  APIUrl: APIL,
  setBottomAlert: () => { },
}


const theme = {
  token: {
    colorLink: '#1890ff',
  },
};



export const GlobalVar = React.createContext();
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    {/* <Watermark content={['cicada.llc', 'CICADA INNOVATE HOLDING']}> */}
      <AlertContext.Provider value={alertContext}>
        <GlobalVar.Provider value={data_GlobalVar}>
          <Context.Provider value={{ store }}>
            <RouterP store={store} />
          </Context.Provider>
        </GlobalVar.Provider>
      </AlertContext.Provider>
    {/* </Watermark> */}
  </React.StrictMode>
);

// document.getElementById("root")