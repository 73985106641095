
import { BiLogoTelegram, BiMailSend, BiPhone } from "react-icons/bi";

import s from './contact.module.scss'

const contactData = [
  'amal_agishev',
]

function ContactPage(props) {
  let { id } = props;
  if (!id) id = 'amal_agishev';
  if (contactData.indexOf(id) === -1)
    return (
      <>
        404
      </>
    )

  window.location.href = `https://teletype.link/${id}`;

  return (
    <>
      redirected to https://teletype.link/{{ id }}
    </>
  )
}

export default ContactPage